import SlimSelect from "../libs/SlimSelect/slimselect.min";
import "../libs/SlimSelect/slimselect.min.css"

export class SelectInput extends HTMLSelectElement {

    connectedCallback() {
        this.select = new SlimSelect({
            select: this,
            settings: {
                searchText: 'Aucun résultat trouvé',
                searchPlaceholder: 'Rechercher',
                searchHighlight: true,
                allowDeselect: true,
            }
        });

        let main = this.parentElement.querySelector(".ss-main")
        if (main !== null) {
            main.addEventListener('focus', (e) => {
                let id = main.id
                if (document.querySelectorAll("#" + id)[1].classList.contains("ss-open-below") === false) {
                    main.click()
                }
            })

            main.addEventListener('mousedown', (e) => {
                e.preventDefault()
            })
        }
    }

    disconnectedCallback() {
        this.select.destroy()
    }
}


export function setCustomSelect(item) {
    return new SlimSelect({
        select: item,
        settings: {
            searchText: 'Aucun résultat trouvé',
            searchPlaceholder: 'Rechercher',
            searchHighlight: true,
            allowDeselect: true,
        }
    })
}

export class SelectInputAddable extends HTMLSelectElement {

    connectedCallback() {
        this.select = new SlimSelect({
            select: this,
            settings: {
                searchText: 'Aucun résultat trouvé',
                searchPlaceholder: 'Rechercher',
                searchHighlight: true,
                allowDeselect: true,
            },
            events: {
                addable: function (value) {
                    // return false or null if you do not want to allow value to be submitted
                    if (value === 'bad') {
                        return false
                    }

                    // Return the value string
                    return value // Optional - value alteration // ex: value.toLowerCase()

                    // Optional - Return a valid data object.
                    // See methods/setData for list of valid options
                    return {
                        text: value,
                        value: value.toLowerCase()
                    }

                    // Optional - Return a promise with either a string or data object
                    return new Promise((resolve) => {
                        resolve({
                            text: value,
                            value: value,
                        })
                    })
                }
            }
        });

        let main = this.parentElement.querySelector(".ss-main")
        if (main !== null) {
            main.addEventListener('focus', (e) => {
                let id = main.id
                if (document.querySelectorAll("#" + id)[1].classList.contains("ss-open-below") === false) {
                    main.click()
                }
            })

            main.addEventListener('mousedown', (e) => {
                e.preventDefault()
            })
        }
    }

    disconnectedCallback() {
        this.select.destroy()
    }
}

