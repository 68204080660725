import $ from 'jquery'
import 'jquery-confirm'
import 'jquery-confirm/css/jquery-confirm.css'
import NotificationToast from "./NotificationToast";

export function CustomConfirm(formElement, text = 'Voulez-vous supprimer cet enregistrement ? <br> Ce processus ne peut pas être annulé.', actionBtn = 'Supprimer') {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Confirmation',
        content: text,
        useBootstrap: false,
        boxWidth: '500px',
        // autoClose: 'Annuler|10000',
        theme: 'modern',
        closeIcon: true,
        animation: 'scale',
        type: 'red',
        buttons: {
            btn: {
                text: actionBtn,
                btnClass: 'btn__confirm__delete',
                action: function() {
                    let url = formElement.action;
                    document.querySelector('.spinner__loader')?.classList.add('active')
                    axios({
                        method: 'post',
                        url: url,
                        responseType: 'json',
                        data:  new FormData(formElement)
                    })
                        .then(function (response) {
                            let data = response.data

                            let redirect_url = data?.redirection
                            let search_params = new URL(redirect_url).search;
                            let current_search_params = window.location.search;
                            if (search_params !== current_search_params && search_params.search("tab") === -1) {
                                redirect_url += current_search_params;
                            }

                            Turbolinks.visit(redirect_url)

                            document.addEventListener("turbolinks:load", function () {
                                if (data !== undefined) {
                                    NotificationToast(data?.type, data?.message)
                                    setTimeout(() => { //remove data after 500ms
                                        data = undefined;
                                    }, 500);
                                }
                            })
                        }).catch(function (error) {
                        NotificationToast("error", error.message)
                        document.querySelector('.spinner__loader')?.classList.remove('active')
                    });

                    document.querySelector(".jconfirm.jconfirm-modern.jconfirm-open").remove()
                }
            },
            Annuler: function () {
            }
        }
    });
}

export function CustomConfirmValidation(text, formElement) {
    $.confirm({
        icon: 'fa fa-check-circle',
        title: 'Confimation',
        content: text,
        useBootstrap: false,
        boxWidth: '500px',
        // autoClose: 'Annuler|10000',
        theme: 'material',
        closeIcon: true,
        animation: 'scale',
        type: 'blue',
        buttons: {
            valider: {
                btnClass: 'btn__confirm__blue',
                action: function() {
                    let url = formElement.action
                    let payload = new FormData(formElement)
                    document.querySelector(".jconfirm.jconfirm-material.jconfirm-open").remove()
                    document.querySelector('.spinner__loader')?.classList.add('active')
                    axios({
                        method: 'post',
                        url: url,
                        responseType: 'json',
                        data:  payload
                    })
                        .then(function (response) {
                            let data = response.data
                            let redirect_url = data?.redirection
                            let search_params = new URL(redirect_url).search;
                            let current_search_params = window.location.search;
                            if (search_params !== current_search_params && search_params.search("tab") === -1) {
                                redirect_url += current_search_params;
                            }
                            Turbolinks.visit(redirect_url)
                            document.addEventListener("turbolinks:load", function () {
                                if (data !== undefined) {
                                    if (data.redirection_tab !== undefined) {
                                        window.open(data.redirection_tab, '_blank')
                                    }
                                    NotificationToast(data?.type, data?.message)
                                    setTimeout(() => { //remove data after 500ms
                                        data = undefined;
                                    }, 500);
                                }
                            })
                        }).catch(function (error) {
                        document.querySelector('.spinner__loader')?.classList.remove('active')
                        NotificationToast("error", error.message)
                    });
                }
            },
            Annuler: function () {
            }
        }
    });
}


export function CustomConfirmLogout(url) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Confirmation',
        content: 'Voulez-vous vraiment vous déconnectez de votre session utilisateur ?',
        useBootstrap: false,
        boxWidth: '500px',
        // autoClose: 'Annuler|10000',
        theme: 'modern',
        closeIcon: true,
        animation: 'scale',
        type: 'red',
        buttons: {
            OUI: {
                btnClass: 'btn__confirm__delete',
                action: function() {

                    axios({
                        method: 'post',
                        url: url,
                        responseType: 'json',
                    })
                        .then(function (response) {
                            let data = response.data

                            let redirect_url = data?.redirection
                            Turbolinks.visit(redirect_url)

                            document.addEventListener("turbolinks:load", function () {
                                if (data !== undefined) {
                                    NotificationToast(data?.type, data?.message)
                                    setTimeout(() => { //remove data after 500ms
                                        data = undefined;
                                    }, 500);
                                }
                            })
                        }).catch(function (error) {
                        NotificationToast("error", error.message)
                    });

                    document.querySelector(".jconfirm.jconfirm-modern.jconfirm-open").remove()
                }
            },
            NON: function () {
            }
        }
    });
}

export function customAlert(message){
    $.alert({
        icon: 'fa fa-exclamation-triangle',
        title: 'Alert!',
        content: message,
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'material',
        closeIcon: true,
        animation: 'scale',
        type: 'red'
    });
}
